import React, { Children, cloneElement } from 'react';
import Step from './Step';

function Stepper({ children, step, form, onFormChange, onStepperChange, onFinish }) {
  const childrens = Children.toArray(children).filter(
    (child) => child.props.entity === form.entity || !child.props.entity,
  );

  function onNext() {
    onStepperChange((prev) => prev + 1);
  }

  function onPrev() {
    onStepperChange((prev) => prev - 1);
  }

  function onSubmit(form) {
    onFormChange((prev) => ({ ...prev, ...form }));
    const isLastStep = step === childrens.length - 2;
    isLastStep ? onFinish(form) : onNext();
  }

  function goToStep(index) {
    onStepperChange(index);
  }

  return (
    <>
      <div className="flex-1">
        {/* Progress bar */}
        <div className="px-4 pt-12 pb-8">
          <div className="max-w-md mx-auto w-full">
            <div className="relative">
              <div
                className="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-slate-200 dark:bg-slate-700"
                aria-hidden="true"
              ></div>
              <ul className="relative flex justify-between w-full">
                {childrens.map((_, index) => (
                  <Step key={index} index={index} active={step === index} onClick={goToStep} />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {childrens.map((children, index) =>
        cloneElement(children, {
          onPrev,
          onSubmit,
          value: form,
          display: step === index,
        }),
      )}
    </>
  );
}

export default Stepper;
