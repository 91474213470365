import React from 'react';

export default function CardForm({ isPaying }) {
  return (
    <>
      {/* Card Number */}
      <div>
        <label className="block text-sm font-medium mb-1" htmlFor="card-nr">
          Número do cartão <span className="text-rose-500">*</span>
        </label>
        <input
          disabled={isPaying}
          id="number"
          name="number"
          className="form-input w-full"
          type="text"
          required
          maxLength={19}
          minLength={13}
          placeholder="1234 1234 1234 1234"
        />
      </div>
      {/* Expiry and CVC */}
      <div className="flex space-x-4">
        <div className="flex-1">
          <label className="block text-sm font-medium mb-1" htmlFor="exp_date">
            Validade <span className="text-rose-500">*</span>
          </label>
          <input
            disabled={isPaying}
            id="exp_date"
            name="exp_date"
            className="form-input w-full"
            type="text"
            required
            pattern="^(0[1-9]|1[0-2])\/\d{2}$"
            maxLength="5"
            placeholder="MM/AA"
            onChange={({ target }) => {
              let { value } = target;
              value = value.replace(/\D/g, '');
              if (value.length > 2) {
                value = `${value.slice(0, 2)}/${value.slice(2)}`;
              }
              target.value = value;
            }}
          />
        </div>
        <div className="flex-1">
          <label className="block text-sm font-medium mb-1" htmlFor="card-cvc">
            CVC <span className="text-rose-500">*</span>
          </label>
          <input
            disabled={isPaying}
            required
            id="cvv"
            type="text"
            name="cvv"
            className="form-input w-full"
            maxLength={4}
            minLength={3}
            placeholder="CVC"
          />
        </div>
      </div>
      {/* Name on Card */}
      <div>
        <label className="block text-sm font-medium mb-1" htmlFor="card-name">
          Nome no cartão <span className="text-rose-500">*</span>
        </label>
        <input
          disabled={isPaying}
          required
          type="text"
          id="holder_name"
          name="holder_name"
          className="form-input w-full"
          placeholder="Seu nome"
          maxLength={64}
        />
      </div>
    </>
  );
}
