export default function PaymentStatus({ order, className }) {
  if (!['pending', 'failed'].includes(order.status)) return null;

  if (order.payment_method === 'pix' && new Date(order.pix.expires_at) < new Date()) {
    order.status = 'failed';
  }

  const messages = {
    pending: {
      title: 'Pagamento pendente',
      header: 'Seu pagamento está pendente',
      description: {
        pix: 'Pague com PIX e tenha acesso imediato aos contatos dos candidatos! Se já pagou, aguarde nossa confirmação.',
        credit_card: 'Estamos processando seu pagamento e em breve você terá acesso aos contatos dos candidatos!',
      },
      button: {
        pix: 'Pagar com PIX',
        credit_card: false,
      },
    },
    failed: {
      title: 'Pagamento falhou',
      header: 'Sua tentativa de pagamento falhou',
      description: {
        pix: 'Não recebemos o pagamento via PIX. Tente novamente para ter acesso imediato aos contatos dos candidatos!',
        credit_card: 'O pagamento com cartão falhou. Insira um cartão válido para ter acesso aos contatos dos candidatos!',
      },
      button: {
        pix: 'Tentar novamente',
        credit_card: 'Atualizar cartão',
      },
    },
  };

  return (
    <div
      className={`bg-slate-50 dark:bg-slate-800/20 p-4 rounded border border-slate-200 dark:border-slate-700 mb-4 ${className}`}
    >
      <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-4">
        {messages[order.status].title}
      </div>
      <ul className="space-y-3">
        <li>
          <div className="text-sm mb-1">
            <a
              className="font-medium text-slate-800 hover:text-slate-900 dark:text-slate-100 dark:hover:text-white"
              href="#0"
            >
              {messages[order.status].header}
            </a>
          </div>
          <div className="text-xs text-slate-500">{messages[order.status].description[order.payment_method]}</div>
        </li>
      </ul>
      <div className="mt-4">
        <a href={`${order.itemCode}/pay`}>
          <button className="btn-sm w-full bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500 shadow-none">
            {messages[order.status].button[order.payment_method]}
          </button>
        </a>
      </div>
    </div>
  );
}
