import React from 'react';

export default function PaymentToggle({ paymentMethod, onChange }) {
  const isCard = paymentMethod === 'CARD';

  function onSelect(e) {
    e.preventDefault();
    const { currentTarget } = e;
    onChange(currentTarget.id);
  }

  return (
    <div className="flex justify-center mb-6">
      <div className="relative flex w-full p-1 bg-slate-50 dark:bg-slate-700/30 rounded">
        <span className="absolute inset-0 m-1 pointer-events-none" aria-hidden="true">
          <span
            className={`absolute inset-0 w-1/2 bg-white dark:bg-slate-700 rounded border border-slate-200 dark:border-slate-600 shadow-sm transition duration-150 ease-in-out ${
              isCard ? 'translate-x-0' : 'translate-x-full'
            }`}
          ></span>
        </span>
        <button
          id="CARD"
          className={`relative flex-1 text-sm font-medium p-1 duration-150 ease-in-out ${isCard ? 'text-slate-600' : ''}`}
          onClick={onSelect}
        >
          Pagar com Cartão
        </button>
        <button
          id="PIX"
          className={`relative flex-1 text-sm font-medium p-1 duration-150 ease-in-out ${isCard ? '' : 'text-slate-600'}`}
          onClick={onSelect}
        >
          Pagar com PIX
        </button>
      </div>
    </div>
  );
}
