import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import ModalBasic from '../../../components/ModalBasic';
import { getCandidateFeedbacks, sendCandidateFeedback } from '../../../common/services/app';

export default function GiveFeedbackModal({ payload, onClose }) {
  const queryClient = useQueryClient();
  const { open, positionId, candidate, feedback } = payload;

  const [state, setState] = useState({
    comment: '',
    feedbackId: payload.feedback,
  });

  const { data = [], isLoading } = useQuery('candidateFeedbacks', getCandidateFeedbacks);
  function mutateFn(data) {
    return toast.promise(sendCandidateFeedback(data), {
      pending: 'Guardando avaliação...',
      success: 'Avaliação enviada com sucesso!',
      error: 'Ocorreu um erro ao enviar avaliação. Tente novamente mais tarde.',
    });
  }

  const { mutate } = useMutation(mutateFn, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      onClose();
    },
  });

  function onSubmit(e) {
    e.preventDefault();
    mutate({ positionId, candidateId: candidate.id, feedback: state });
  }

  const checkedOption = state.feedbackId || feedback;

  return (
    <ModalBasic id="feedback-modal" modalOpen={open} setModalOpen={onClose} title="Avaliar candidato">
      <form onSubmit={onSubmit}>
        <div className="px-4 py-2 sm:px-5 sm:py-4">
          {/* Modal content */}
          <div className="text-sm mb-4">
            <div className="space-y-2">
              <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">
                Esperamos que você tenha tido uma boa experiência com{' '}
                <span className="font-medium">{candidate?.name.split(' ')[0]}!</span>
              </div>
              <p>
                Para nos ajudar a melhorar a experiência de recrutamento, por favor, nos dê um feedback sobre o
                candidato.
              </p>
            </div>
          </div>
          <div className="space-y-2">
            {/* Checkbox */}
            <div className="py-1">
              <label className="block text-sm font-medium mb-1" htmlFor="motive">
                O que aconteceu? <span className="text-rose-500">*</span>
              </label>
              <div className="flex flex-wrap items-center -m-2 mt-1">
                {data.map(({ id, feedback }) => (
                  <div key={id} className="m-2">
                    {/* Start */}
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="feedback"
                        required
                        className="form-radio"
                        onChange={() => setState((prev) => ({ ...prev, feedbackId: id }))}
                        checked={id === checkedOption}
                      />
                      <span className="text-sm ml-2">{feedback}</span>
                    </label>
                    {/* End */}
                  </div>
                ))}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="feedback">
                Deseja adicionar algum comentário?
              </label>
              <textarea
                id="feedback"
                name="feedback"
                onChange={(e) => setState({ ...state, comment: e.target.value })}
                className="form-textarea w-full px-2 py-1"
                rows="3"
              ></textarea>
            </div>
            <div className="text-xs text-slate-500">
              <p>Comentários não são compartilhados com o candidato.</p>
            </div>
          </div>
        </div>
        {/* Modal footer */}
        <div className="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
          <div className="flex flex-wrap justify-end space-x-2">
            <button
              disabled={isLoading}
              className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              Cancelar
            </button>
            <button disabled={isLoading} className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white">
              Enviar avaliação
            </button>
          </div>
        </div>
      </form>
    </ModalBasic>
  );
}
