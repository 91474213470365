import React, { useContext } from 'react';
import { UserContext } from '../../common/hooks/useUser';

function AccountPanel() {
  const { user, firebaseData = {} } = useContext(UserContext);
  const { data } = firebaseData;

  return (
    <section>
      <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Meu perfil</h2>
      <div className="text-sm">Mantenha seu perfil atualizado para que as empresas possam encontrar você.</div>
      <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
        <div className="sm:w-1/3">
          <label className="block text-sm font-medium mb-1" htmlFor="name">
            Seu nome
          </label>
          <input id="name" readOnly className="form-input w-full" type="text" value={data?.displayName || ''} />
        </div>
        <div className="sm:w-1/3">
          <label className="block text-sm font-medium mb-1" htmlFor="experience_sector">
            Setor
          </label>
          <input
            id="experience_sector"
            readOnly
            value={user?.experience_sector || ''}
            className="form-input w-full"
            type="text"
          />
        </div>
        <div className="sm:w-1/3">
          <label className="block text-sm font-medium mb-1" htmlFor="experience_type">
            Experiência
          </label>
          <input
            id="experience_type"
            readOnly
            value={user?.experience_type || ''}
            className="form-input w-full"
            type="text"
          />
        </div>
      </div>
      <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
        <div className="sm:w-1/3">
          <label className="block text-sm font-medium mb-1" htmlFor="education_level">
            Escolaridade
          </label>
          <input
            id="education_level"
            readOnly
            value={user?.education_level || ''}
            className="form-input w-full"
            type="text"
          />
        </div>
        <div className="sm:w-1/3">
          <label className="block text-sm font-medium mb-1" htmlFor="work_preference">
            Trabalho de preferência
          </label>
          <input
            id="work_preference"
            readOnly
            value={user?.work_preference || ''}
            className="form-input w-full"
            type="text"
          />
        </div>
        <div className="sm:w-1/3">
          <label className="block text-sm font-medium mb-1" htmlFor="experience_duration">
            Experiência
          </label>
          <input
            id="experience_duration"
            readOnly
            value={user?.experience_duration || ''}
            className="form-input w-full"
            type="text"
          />
        </div>
      </div>
    </section>
  );
}

export default AccountPanel;
