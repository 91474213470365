import React from 'react';

import JobListItem from './CadidateJobListItem';

export const openPositionStatus = ['PENDING', 'RELEASED'];

function CompanyProfile({ applications = [] }) {
  const app = applications.map(({ Position, status, appliedAt }) => ({
    role: Position.experience_type,
    type: status,
    date: appliedAt,
    ...Position,
  }));

  const items = [
    app.filter(({ type }) => openPositionStatus.includes(type)),
    app.filter(({ type }) => !openPositionStatus.includes(type)),
  ];

  return (
    <>
      {/* Page content */}
      <div className="max-w-3xl mx-auto">
        <h3 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-6">Últimas vagas</h3>

        {/* Job list */}
        <div className="space-y-6">
          {/* Group 1 */}
          {items[0].length > 0 && (
            <div>
              <h4 className="text-slate-800 dark:text-slate-100 font-medium mb-4">Você está concorrendo</h4>
              {/* Job list */}
              <div className="space-y-2">
                {items[0].map((item) => {
                  return <JobListItem key={item.id} {...item} />;
                })}
              </div>
            </div>
          )}

          {/* Group 2 */}
          {items[1].length > 0 && (
            <div>
              <h4 className="text-slate-800 dark:text-slate-100 font-medium mb-4">Encerradas</h4>
              {/* Job list */}
              <div className="space-y-2">
                {items[1].map((item) => {
                  return <JobListItem key={item.id} {...item} />;
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CompanyProfile;
