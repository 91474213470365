import React, { useContext } from 'react';
import { UserContext } from '../../common/hooks/useUser';

import Image from '../../images/user-avatar-80.png';
import CompanyProfile from './AccountPanelCompany';
import CandidateProfile from './AccountPanelCandidate';

function AccountPanel() {
  const { isCompany } = useContext(UserContext);

  return (
    <div className="grow">
      {/* Panel body */}
      <div className="p-6 space-y-6 pb-44">
        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">Minha conta</h2>
        {/* Picture */}
        <section className="hidden">
          <div className="flex items-center">
            <div className="mr-4">
              <img className="w-20 h-20 rounded-full" src={Image} width="80" height="80" alt="User upload" />
            </div>
            <button className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white">Change</button>
          </div>
        </section>
        {/* Business Profile */}
        {isCompany ? <CompanyProfile /> : <CandidateProfile />}
        {/* Email */}
        <section className="hidden">
          <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Email</h2>
          <div className="text-sm">Atualize seu email para receber notificações e lembretes.</div>
          <div className="flex flex-wrap mt-5">
            <div className="mr-2">
              <label className="sr-only" htmlFor="email">
                Email
              </label>
              <input id="email" className="form-input" type="email" />
            </div>
            <button className="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm text-indigo-500">
              Alterar
            </button>
          </div>
        </section>
        {/* Password */}
        <section className="hidden">
          <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Senha</h2>
          <div className="text-sm">
            Você pode definir uma senha permanente para sua conta ao invés de usar códigos de acesso.
          </div>
          <div className="mt-5">
            <button className="btn border-slate-200 dark:border-slate-700 shadow-sm text-indigo-500">
              Definir senha
            </button>
          </div>
        </section>
        {/* Smart Sync */}
        {/* <section>
          <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">
            Smart Sync update for Mac
          </h2>
          <div className="text-sm">
            With this update, online-only files will no longer appear to take up hard drive space.
          </div>
          <div className="flex items-center mt-5">
            <div className="form-switch">
              <input type="checkbox" id="toggle" className="sr-only" checked={sync} onChange={() => setSync(!sync)} />
              <label className="bg-slate-400 dark:bg-slate-700" htmlFor="toggle">
                <span className="bg-white shadow-sm" aria-hidden="true"></span>
                <span className="sr-only">Enable smart sync</span>
              </label>
            </div>
            <div className="text-sm text-slate-400 dark:text-slate-500 italic ml-2">{sync ? 'On' : 'Off'}</div>
          </div>
        </section> */}
      </div>
      {/* Panel footer */}
      <footer className="hidden">
        <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
          <div className="flex self-end">
            <button className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300">
              Cancel
            </button>
            <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3">Salvar alterações</button>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default AccountPanel;
