import React from 'react';
import { useSearchParams } from 'react-router-dom';

function PaginationNumeric({ totalItems }) {
  const totalPages = Math.ceil(totalItems / 10);
  const [currentQueryParameters, setSearchParams] = useSearchParams();
  const newQueryParameters = new URLSearchParams();

  function PaginationItem({ id }) {
    const styles = {
      active:
        'rounded-l leading-5 px-3.5 py-2 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 text-indigo-500',
      default:
        'leading-5 px-3.5 py-2 bg-white dark:bg-slate-800 hover:bg-indigo-500 dark:hover:bg-indigo-500 border border-slate-200 dark:border-slate-700 text-slate-600 dark:text-slate-300 hover:text-white',
    };

    const className = [
      'inline-flex items-center justify-center',
      id === currentQueryParameters.get('page') ? styles.active : styles.default,
    ];

    return (
      <li>
        <button
          onClick={() => {
            newQueryParameters.set('page', id);
            setSearchParams(newQueryParameters);
          }}
          className={className.join(' ')}
        >
          {id}
        </button>
      </li>
    );
  }

  return (
    <div className="flex justify-center">
      <nav className="flex" role="navigation" aria-label="Navigation">
        <div className="mr-2">
          <span className="inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 text-slate-300 dark:text-slate-600">
            <span className="sr-only">Anterior</span>
            <wbr />
            <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
              <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z" />
            </svg>
          </span>
        </div>
        <ul className="inline-flex text-sm font-medium -space-x-px shadow-sm">
          <PaginationItem id={1} />
          {totalPages > 4 &&
            Array.from({ length: totalPages - 2 }, (_, id) => id + 2).map((id) => <PaginationItem key={id} id={id} />)}
          {totalPages > 5 && (
            <li>
              <span className="inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 text-slate-400 dark:text-slate-500">
                …
              </span>
            </li>
          )}
          {totalPages > 1 && <PaginationItem id={totalPages} />}
        </ul>
        <div className="ml-2">
          <a
            href="#0"
            className="inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white dark:bg-slate-800 hover:bg-indigo-500 dark:hover:bg-indigo-500 border border-slate-200 dark:border-slate-700 text-slate-600 dark:text-slate-300 hover:text-white shadow-sm"
          >
            <span className="sr-only">Próximo</span>
            <wbr />
            <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
              <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
            </svg>
          </a>
        </div>
      </nav>
    </div>
  );
}

export default PaginationNumeric;
