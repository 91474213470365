import { modals } from './modal-enum';
import { useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { MdCheck } from 'react-icons/md';

import GenericModal from './GenericModal';
import Button from '../../../../components/Button';

export default function Actions({ status, className, openReleaseCandidatesModal }) {
  const initialState = { open: false, options: {} };
  const [modal, setModal] = useState(initialState);
  const isRecruitment = status === 'RECRUITMENT';
  const isWaitingCandidates = status === 'WAITING_CANDIDATES';
  const isCandidatesReleased = status === 'RELEASED_CANDIDATES';
  const isClosed = status === 'CLOSED';
  const isCandidatesReview = status === 'CANDIDATES_REVIEW';
  const isCancelled = status === 'CANCELLED';

  function handleAction(e) {
    e.stopPropagation();
    const id = e.currentTarget.id;
    setModal({ open: true, options: modals[id] });
  }

  function onClose() {
    setModal(initialState);
  }

  if (isCancelled) return <></>;

  return (
    <>
      <div
        className={`${className} bg-white dark:bg-slate-800 p-5 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 lg:w-72 xl:w-80`}
      >
        <div className="space-y-2">
          {isCandidatesReleased && (
            <button
              id="FINISH"
              className="btn w-full bg-indigo-500 hover:bg-indigo-600 text-white"
              onClick={handleAction}
            >
              <svg className="w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16">
                <MdCheck />
              </svg>
              <span className="ml-1">Encerrar vaga</span>
            </button>
          )}

          {(isRecruitment || isCandidatesReview) && (
            <button
              className="btn w-full bg-indigo-500 hover:bg-indigo-600 text-white"
              onClick={openReleaseCandidatesModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-lock w-4 h-4"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6z" />
                <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0" />
                <path d="M8 11v-4a4 4 0 1 1 8 0v4" />
              </svg>
              <span className="ml-1">Liberar contatos</span>
            </button>
          )}

          {isWaitingCandidates && (
            <Button disabled className={'w-full'}>
              <span className="ml-1">Aguardando candidatos...</span>
            </Button>
          )}

          {isCancelled && (
            <Button disabled className={'w-full'}>
              <span className="ml-1">Vaga cancelada</span>
            </Button>
          )}

          {isClosed && (
            <Button disabled className={'w-full'}>
              <span className="ml-1">Vaga encerrada!</span>
            </Button>
          )}

          {!isCandidatesReleased && !isClosed && (
            <button
              id="CANCEL"
              className="btn w-full dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-rose-500"
              onClick={handleAction}
            >
              <svg className="w-4 h-4 fill-current dark:text-rose-600 text-rose-500 " viewBox="0 0 14 14">
                <IoCloseSharp />
              </svg>
              <span className="ml-1">Cancelar vaga</span>
            </button>
          )}
        </div>
      </div>
      <GenericModal modal={modal} onClose={onClose} />
    </>
  );
}
