import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { syncUser } from '../../../common/services/app';
import { refreshUser } from '../../../common/services/auth';

import OnboardingImage from '../../../images/onboarding-image.jpg';
import OnboardingDecoration from '../../../images/auth-decoration.png';
import Loading from '../../../components/app/Loading';

import CompanyForm from './company/Form';
// import CandidateForm from './CandidateForm';

function Onboarding01() {
  const navigate = useNavigate();
  const { status, mutate } = useMutation('syncUser', syncUser, {
    onSuccess: async ({ result }) => {
      const user = await refreshUser();
      if (result === 'Not Synced' || !user.displayName) return;
      navigate('/');
    },
  });

  useEffect(() => {
    mutate();
  }, []);

  const showOnboarding = !['idle', 'loading'].includes(status);

  return (
    <main className="bg-white dark:bg-slate-900">
      {!showOnboarding && <Loading />}
      {showOnboarding && (
        <div className="relative flex">
          {/* Content */}
          <CompanyForm />

          {/* Image */}
          <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
            <img
              className="object-cover object-center w-full h-full"
              src={OnboardingImage}
              width="760"
              height="1024"
              alt="Onboarding"
            />
            <img
              className="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
              src={OnboardingDecoration}
              width="218"
              height="224"
              alt="Authentication decoration"
            />
          </div>
        </div>
      )}
    </main>
  );
}

export default Onboarding01;
