import React from 'react';
import ModalBlank from '../../../components/ModalBlank';
import Button from '../../../components/Button';

export default function ConfirmationModal({ position, modalOpen, setModalOpen, onSubmit, isLoading }) {
  return (
    <ModalBlank id="info-modal" modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="p-5 flex space-x-4">
        {/* Icon */}
        <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-indigo-100 dark:bg-indigo-500/30">
          <svg className="w-4 h-4 shrink-0 fill-current text-indigo-500" viewBox="0 0 16 16">
            <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" />
          </svg>
        </div>
        {/* Content */}
        <div>
          {/* Modal header */}
          <div className="mb-2">
            <div className="text-lg font-semibold text-slate-800 dark:text-slate-100">Criar vaga?</div>
          </div>
          {/* Modal content */}
          <div className="text-sm mb-7">
            <div className="space-y-2">
              Você está prestes a criar uma nova vaga. Após a criação, não será possível editar a vaga, apenas
              desativá-la. <br />
              <div className="mt-3">
                <div className="text-sm font-semibold text-slate-700 dark:text-slate-100">
                  Tem certeza que deseja criar a vaga?
                </div>
              </div>
            </div>
          </div>
          {/* Modal footer */}
          <div className="flex flex-wrap justify-end space-x-2">
            <Button disabled={isLoading} size="sm" variant="tertiary" onClick={() => setModalOpen(false)}>
              Cancelar
            </Button>
            <Button
              size="sm"
              loading={isLoading}
              className="min-w-[8rem]"
              onClick={() => {
                onSubmit(position);
              }}
            >
              Sim, criar vaga
            </Button>
          </div>
        </div>
      </div>
    </ModalBlank>
  );
}
