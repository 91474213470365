import Avatar from '../../../../components/Avatar';
import Skeleton from './CandidatesListSkeleton';
import FindingCandidates from './FindingCandidates';
import CancelledPosition from './CancelledPosition';

export default function CandidatesList({ data, status, isLoading, hasCandidates, canViewCandidates }) {
  if (isLoading) return <Skeleton />;
  if (status === 'CANCELLED') return <CancelledPosition />;
  if (!hasCandidates || data?.length === 0) return <FindingCandidates />;

  return (
    <ul className={`space-y-3 min-h-40 ${canViewCandidates ? '' : 'blur-sm pointer-events-none'}`}>
      {data?.map(({ Candidate }) => (
        <li key={Candidate.name}>
          <div className="flex justify-between">
            <div className="grow flex items-center">
              <div className="relative mr-3">
                <Avatar>{Candidate.nameInitials}</Avatar>
              </div>
              <div className="truncate">
                <span className="text-sm font-medium text-slate-800 dark:text-slate-100">{Candidate.name}</span>
              </div>
            </div>
            {/* <button className="text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 rounded-full">
              <span className="sr-only">Menu</span>
              <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <circle cx="16" cy="16" r="2" />
                <circle cx="10" cy="16" r="2" />
                <circle cx="22" cy="16" r="2" />
              </svg>
            </button> */}
          </div>
        </li>
      ))}
    </ul>
  );
}
