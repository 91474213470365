import React, { useContext } from 'react';
import { TbCopyPlusFilled } from 'react-icons/tb';
import { checkPositionRoute } from '../partials/Sidebar';
import { useLocation, NavLink } from 'react-router-dom';
import { UserContext } from '../common/hooks/useUser';

export default function FooterNav() {
  const location = useLocation();
  const { pathname } = location;
  const { isCompany, isCandidate, isLoading } = useContext(UserContext);
  const isPositionRoute = checkPositionRoute(pathname);

  if (isLoading) return null;

  const options = [
    {
      name: 'Vagas',
      to: isCompany ? '/positions' : '/jobs',
      active: isPositionRoute,
      svg: (
        <svg className="shrink-0 h-5 w-5" viewBox="0 0 24 24">
          <path
            className={`fill-current ${isPositionRoute ? 'text-indigo-500' : 'text-slate-600'}`}
            d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"
          />
          <path
            className={`fill-current ${isPositionRoute ? 'text-indigo-300' : 'text-slate-400'}`}
            d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"
          />
        </svg>
      ),
    },
    {
      name: 'Criar vaga',
      to: '/positions/new',
      hidden: isCandidate,
      svg: (
        <TbCopyPlusFilled
          className={`shrink-0 h-5 w-5 fill-current ${
            pathname === '/positions/new' ? 'text-indigo-500' : 'text-slate-600'
          }`}
        />
      ),
    },
    // {
    //   name: 'Pagamentos',
    //   to: '/finance/transactions',
    //   svg: (
    //     <svg className="shrink-0 h-5 w-5" viewBox="0 0 24 24">
    //       <path
    //         className={`fill-current ${pathname.includes('finance') ? 'text-indigo-300' : 'text-slate-400'}`}
    //         d="M13 6.068a6.035 6.035 0 0 1 4.932 4.933H24c-.486-5.846-5.154-10.515-11-11v6.067Z"
    //       />
    //       <path
    //         className={`fill-current ${pathname.includes('finance') ? 'text-indigo-500' : 'text-slate-700'}`}
    //         d="M18.007 13c-.474 2.833-2.919 5-5.864 5a5.888 5.888 0 0 1-3.694-1.304L4 20.731C6.131 22.752 8.992 24 12.143 24c6.232 0 11.35-4.851 11.857-11h-5.993Z"
    //       />
    //       <path
    //         className={`fill-current ${pathname.includes('finance') ? 'text-indigo-600' : 'text-slate-600'}`}
    //         d="M6.939 15.007A5.861 5.861 0 0 1 6 11.829c0-2.937 2.167-5.376 5-5.85V0C4.85.507 0 5.614 0 11.83c0 2.695.922 5.174 2.456 7.17l4.483-3.993Z"
    //       />
    //     </svg>
    //   ),
    // },
    {
      name: 'Configurações',
      to: '/settings/account',
      svg: (
        <svg className="shrink-0 h-5 w-5" viewBox="0 0 24 24">
          <path
            className={`fill-current ${pathname.includes('settings') ? 'text-indigo-500' : 'text-slate-600'}`}
            d="M19.714 14.7l-7.007 7.007-1.414-1.414 7.007-7.007c-.195-.4-.298-.84-.3-1.286a3 3 0 113 3 2.969 2.969 0 01-1.286-.3z"
          />
          <path
            className={`fill-current ${pathname.includes('settings') ? 'text-indigo-300' : 'text-slate-400'}`}
            d="M10.714 18.3c.4-.195.84-.298 1.286-.3a3 3 0 11-3 3c.002-.446.105-.885.3-1.286l-6.007-6.007 1.414-1.414 6.007 6.007z"
          />
          <path
            className={`fill-current ${pathname.includes('settings') ? 'text-indigo-500' : 'text-slate-600'}`}
            d="M5.7 10.714c.195.4.298.84.3 1.286a3 3 0 11-3-3c.446.002.885.105 1.286.3l7.007-7.007 1.414 1.414L5.7 10.714z"
          />
          <path
            className={`fill-current ${pathname.includes('settings') ? 'text-indigo-300' : 'text-slate-400'}`}
            d="M19.707 9.292a3.012 3.012 0 00-1.415 1.415L13.286 5.7c-.4.195-.84.298-1.286.3a3 3 0 113-3 2.969 2.969 0 01-.3 1.286l5.007 5.006z"
          />
        </svg>
      ),
    },
  ].filter(({ hidden }) => !hidden);

  return (
    <div className="mt-11 lg:hidden z-30">
      <footer className="fixed bottom-0 w-full h-16 bg-white dark:bg-[#182235] border-t border-slate-200 dark:border-slate-700 shadow-lg">
        <div className={`grid h-full max-w-lg ${isCompany ? 'grid-cols-3' : 'grid-cols-2'} mx-auto font-medium`}>
          {options.map(({ name, to, svg, active }) => (
            <NavLink
              key={name}
              end
              type="button"
              to={to}
              className={({ isActive }) =>
                `inline-flex flex-col items-center justify-center hover:bg-gray-50 dark:hover:bg-gray-800 group text-xs text-center
              ${
                isActive || active
                  ? 'text-indigo-500 bg-slate-100 dark:bg-gray-800 dark:text-indigo-400 border-b-2 border-indigo-500 dark:border-indigo-400'
                  : 'text-gray-500 dark:text-gray-400 hover:text-slate-700'
              } 
              group-hover:text-indigo-600 dark:group-hover:text-indigo-400`
              }
            >
              {svg}
              {name}
            </NavLink>
          ))}
        </div>
      </footer>
    </div>
  );
}
