import React from 'react';
import Address from '../../../components/Address';

function AddressQuestion({ value, display, isLoading, onPrev, onSubmit }) {
  function handleChange(Address) {
    onSubmit({ ...value, Address });
  }

  const finalMessage = {
    candidate: {
      title: 'Para finalizar, qual é o seu endereço?',
      subtitle: 'Utilizaremos essa informação para selecionar as melhores vagas na sua região!',
    },
    company: {
      title: 'Para finalizar, qual é o endereço da empresa?',
      subtitle: 'Utilizaremos essa informação para selecionar os melhores profissionais na sua região!',
    },
  };

  const { title, subtitle } = finalMessage[value?.entity];

  return (
    <div className="px-4 py-8" style={{ display: display ? 'block' : 'none' }}>
      <div className="max-w-md mx-auto">
        <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">
          Perfeito, {value?.responsible_name?.split(' ')[0] || value?.name?.split(' ')[0]}! <br /> {title}
        </h1>
        <p className="text-sm text-gray-600 dark:text-gray-400 mb-8">{subtitle}</p>
        {/* htmlForm */}
        <Address value={value['Address']} isLoading={isLoading} onPrev={onPrev} onSubmit={handleChange} />
      </div>
    </div>
  );
}

export default AddressQuestion;
