import React from 'react';
import PayBg from '../../../../images/pay-bg.jpg';

export default function HeaderBg() {
  return (
    <div className="relative pt-8">
      <div className="absolute inset-0 bg-slate-800 overflow-hidden" aria-hidden="true">
        <img
          className="object-cover h-full w-full filter blur opacity-10"
          src={PayBg}
          width="460"
          height="180"
          alt="Pay background"
        />
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8 max-w-lg mx-auto">
        <img className="rounded-t shadow-lg" src={PayBg} width="460" height="180" alt="Pay background" />
      </div>
    </div>
  );
}
