import React from 'react';

import { useQuery } from 'react-query';
import { formatDate, formatPrice } from '../../common/utils';

import { getTransactions } from '../../common/services/app';
import InvoicesTable from '../../partials/invoices/InvoicesTable';
import PaginationClassic from '../../components/PaginationClassic';

function Transactions() {
  const {
    data = {},
    isLoading,
    isError,
  } = useQuery('getTransactions', getTransactions, {
    select: ({ orders, totalItems }) => {
      return {
        invoices: orders.map(({ id, code, amount, status, created_at, paid_at, item, itemCode, payment_method }) => ({
          id,
          invoice: code,
          status,
          customer: item,
          itemCode,
          total: formatPrice(amount),
          issueddate: formatDate(created_at),
          paiddate: paid_at ? formatDate(paid_at) : '',
          type: payment_method,
        })),
        totalItems,
      };
    },
  });

  return (
    <div className="pb-44">
      {isLoading && <div>Obtendo dados...</div>}
      {(isError || data?.invoices?.length === 0) && <div>Nenhuma fatura encontrada.</div>}
      {data?.invoices?.length > 0 && (
        <>
          <InvoicesTable data={data} />
          <div className="mt-8">
            <PaginationClassic totalItems={data.totalItems} />
          </div>
        </>
      )}
    </div>
  );
}

export default Transactions;
