import React, { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition';

function InputAutomplete(props) {
  const { options } = props;
  const [value, setValue] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  function filter(option) {
    return option.toLowerCase().includes(value.toLowerCase());
  }

  function handleOnChange(e) {
    setValue(e.target.value);
    setDropdownOpen(value && options.some(filter));
  }

  return (
    <div className="relative inline-flex w-full">
      <input value={value} onChange={handleOnChange} {...props} className={`form-input w-full ${props.className}`} />

      <Transition
        show={dropdownOpen}
        tag="div"
        className="max-h-40 overflow-y-auto z-10 absolute top-full left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          className="font-medium text-sm text-slate-600 dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700"
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {options.filter(filter).map((option, index) => {
            return (
              <button
                key={index}
                tabIndex="0"
                className={`flex items-center justify-between w-full hover:bg-slate-50 dark:hover:bg-slate-700/20 py-2 px-3 cursor-pointer ${option.toLowerCase() === value.toLowerCase() && 'text-indigo-500'}`}
                onClick={(e) => {
                  e.preventDefault();
                  setValue(option);
                  setDropdownOpen(false);
                }}
              >
                <span>{option}</span>
                <svg
                  className={`shrink-0 mr-2 fill-current text-indigo-500 ${option !== value && 'invisible'}`}
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                >
                  <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                </svg>
              </button>
            );
          })}
        </div>
      </Transition>
    </div>
  );
}

export default InputAutomplete;
