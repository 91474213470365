import axios from 'axios';
import { attachToken } from './auth';

// Server envs
const devEnv = 'http://localhost:3000';
const prodEnv = 'https://api-trabalha-ai-15be4ed54be4.herokuapp.com';

//create a new axios instance
const instance = axios.create({
    baseURL: (process.env.NODE_ENV === 'production' ? prodEnv : devEnv) + '/api/v1' //Set default baseUrl
});

// Request interceptor adding the token to the header
instance.interceptors.request.use(attachToken);

// Response interceptor returning only the data
instance.interceptors.response.use(({ data }) => data, error => {
    if (error?.response?.status === 401) {
        window.location.href = '/auth/login';
    }
    return Promise.reject(error);
});

export default instance