import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getPositionFormEnum } from '../../../common/services/app.ts';

import Select from '../../../components/Select';

function CompanyInfo({ display, onSubmit, onPrev }) {
  const { data } = useQuery('position-form-enum', getPositionFormEnum);

  function handleFormSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    onSubmit({
      experience_sector: formData.get('experience_sector'),
    });
  }

  function getOptions(param) {
    return data ? data[param] : [];
  }

  return (
    <div className="px-4 py-8" style={{ display: display ? 'block' : 'none' }}>
      <div className="max-w-md mx-auto">
        <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">Dados profissionais</h1>
        <p className="text-sm text-gray-600 dark:text-gray-400 mb-8">
          Queremos saber um pouco sobre sua experiência profissional para te ajudar a encontrar a vaga que mais combina
          com você.
        </p>
        {/* htmlForm */}
        <form onSubmit={handleFormSubmit}>
          <div className="space-y-4 mb-8">
            {/* Sector */}
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="experience_sector">
                Setor <span className="text-rose-500">*</span>
              </label>
              <div className="text-xs mb-1">Qual setor ou área você tem mais experiência?</div>
              <Select id="experience_sector" name="experience_sector" required options={getOptions('sectors')} />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <Link className="text-sm underline hover:no-underline" onClick={onPrev}>
              &lt;- Voltar
            </Link>
            <button type="submit" className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-auto">
              Prosseguir -&gt;
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CompanyInfo;
