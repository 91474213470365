import React from 'react';

export default function Input(props) {
  function getClassName() {
    const { className, disabled } = props;
    const classes = [className];
    if (disabled) {
      classes.push(
        'disabled:border-slate-200 dark:disabled:border-slate-700 disabled:bg-slate-100 dark:disabled:bg-slate-800 disabled:text-slate-400 dark:disabled:text-slate-600 disabled:cursor-not-allowed',
      );
    }
    return classes.join(' ');
  }
  return <input name={props.id} {...props} className={`form-input w-full ${getClassName()}`} />;
}
