import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useQuery } from 'react-query';
import { getUser, getPositionFormEnum } from '../../../common/services/app';

import InputAutocomplete from '../../../components/InputAutocomplete';
import Select from '../../../components/Select';
import EditAddress from './EditAddress';

function PositionsForm({ onSubmit, isLoading }) {
  const otherOption = 'Outro';
  const [selectedOther, setSelectedOther] = useState(false);
  const [Address, setAddress] = useState({});
  const { data: user, isFetching } = useQuery('user', getUser, {
    onSuccess: ({ Address }) => setAddress(Address),
  });
  const { data } = useQuery('position-form-enum', getPositionFormEnum, {
    enabled: !!user?.sector,
    select: ({ experience_type, ...data }) => ({
      ...data,
      experience_type: [...experience_type[user?.sector], otherOption],
    }),
  });

  const getOptions = (option) => (data ? data[option] : []);

  function handleSelectedOther({ currentTarget }) {
    setSelectedOther(currentTarget.value === otherOption);
  }

  function handleSubmit(e) {
    e.preventDefault();
    const position = !Address.id ? { Address } : {};
    new FormData(e.target).forEach((value, key) => {
      position[key] = value;
    });
    if (position?.experience_type_other) {
      position.experience_type = position.experience_type_other;
      delete position.experience_type_other;
    }
    onSubmit(position);
  }

  return (
    <form className={`grow ${isLoading ? 'opacity-50' : ''}`} disabled={isLoading} onSubmit={handleSubmit}>
      {/* Panel body */}
      <div className="p-6 lg:px-12 space-y-6 lg:flex gap-10">
        {/* General */}
        <section className="lg:mt-6">
          <h3 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Requisitos da vaga</h3>
          <ul>
            <li className="flex justify-between flex-col gap-3 py-3 border-b lg:border-b-0 border-slate-200 dark:border-slate-700">
              {/* Left */}
              <div className="max-w-md">
                <div className="text-slate-800 dark:text-slate-100 font-semibold">
                  Cargo <span className="text-rose-500">*</span>
                </div>
                <div className="text-sm">Selecione o cargo que você está buscando para a sua empresa</div>
              </div>
              {/* Right */}
              <div className="flex flex-col sm:flex-row gap-3">
                <Select
                  onChange={handleSelectedOther}
                  id="experience_type"
                  name="experience_type"
                  required
                  options={getOptions('experience_type')}
                />
                {selectedOther && (
                  <InputAutocomplete
                    id="experience_type_other"
                    name="experience_type_other"
                    placeholder="Qual?"
                    required
                    options={getOptions('experience_type_other')}
                  />
                )}
              </div>
            </li>

            <li className="flex justify-between flex-col gap-3 py-3 border-b lg:border-b-0 border-slate-200 dark:border-slate-700">
              {/* Left */}
              <div className="max-w-md">
                <div className="text-slate-800 dark:text-slate-100 font-semibold">
                  Experiência <span className="text-rose-500">*</span>
                </div>
                <div className="text-sm">
                  Qual é o tempo mínimo de experiência que o profissional precisa ter nesse cargo?
                </div>
              </div>
              {/* Right */}
              <div className="flex items-center">
                <Select
                  id="experience_level"
                  name="experience_level"
                  required
                  options={getOptions('experience_level')}
                />
              </div>
            </li>
            <li className="flex justify-between flex-col gap-3 py-3 border-b lg:border-b-0 border-slate-200 dark:border-slate-700">
              {/* Left */}
              <div className="max-w-md">
                <div className="text-slate-800 dark:text-slate-100 font-semibold">
                  Escolaridade <span className="text-rose-500">*</span>
                </div>
                <div className="text-sm">Qual é o menor nível de escolaridade obrigatório para esse cargo?</div>
              </div>
              {/* Right */}
              <div className="flex items-center">
                <Select id="education_level" name="education_level" required options={getOptions('education_level')} />
              </div>
            </li>
          </ul>
        </section>

        {/* Shares */}
        <section>
          <h3 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Detalhes da vaga</h3>
          <ul>
            <li className="flex justify-between flex-col gap-3 py-3 border-b lg:border-b-0 border-slate-200 dark:border-slate-700">
              {/* Left */}
              <div className="max-w-md">
                <div className="text-slate-800 dark:text-slate-100 font-semibold">
                  Jornada de trabalho <span className="text-rose-500">*</span>
                </div>
                <div className="text-sm">Qual é o horário que o profissional irá trabalhar?</div>
              </div>
              {/* Right */}
              <div className="flex items-center">
                <Select id="work_schedule" name="work_schedule" required options={getOptions('work_schedule')} />
              </div>
            </li>
            <li className="flex justify-between flex-col gap-3 py-3 border-b lg:border-b-0 border-slate-200 dark:border-slate-700">
              {/* Left */}
              <div className="max-w-md">
                <div className="text-slate-800 dark:text-slate-100 font-semibold">
                  Remuneração <span className="text-rose-500">*</span>
                </div>
                <div className="text-sm">Qual é a faixa salarial para esse cargo?</div>
              </div>
              {/* Right */}
              <div className="flex items-center">
                <Select id="salary_range" name="salary_range" required options={getOptions('salary_range')} />
              </div>
            </li>
            <li className="flex justify-between flex-col gap-3 py-3 border-b lg:border-b-0 border-slate-200 dark:border-slate-700">
              {/* Left */}
              <div className="max-w-md">
                <div className="text-slate-800 dark:text-slate-100 font-semibold">
                  Regime de trabalho <span className="text-rose-500">*</span>
                </div>
                <div className="text-sm">Informe o tipo de contrato que será oferecido para o profissional</div>
              </div>
              {/* Right */}
              <div className="flex items-center">
                <Select id="contract_type" name="contract_type" required options={getOptions('contract_type')} />
              </div>
            </li>
            <li className="flex justify-between flex-col gap-3 py-3 border-b lg:border-b-0 border-slate-200 dark:border-slate-700">
              {/* Left */}
              <div className="max-w-md">
                <div className="text-slate-800 dark:text-slate-100 font-semibold">
                  Local de trabalho <span className="text-rose-500">*</span>
                </div>
                <div className="text-sm">
                  Qual é o endereço onde o profissional irá trabalhar? (Só altere se for diferente do endereço da
                  empresa.)
                </div>
              </div>
              {/* Right */}
              <div className="flex items-center">
                <EditAddress value={Address} isFetching={isFetching} onSubmit={setAddress} />
              </div>
            </li>
          </ul>
        </section>
      </div>

      {/* Panel footer */}
      <footer>
        <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
          <div className="flex self-end">
            <Link
              className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
              to="/positions"
            >
              Voltar
            </Link>
            <button type="submit" className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3">
              Criar vaga
            </button>
          </div>
        </div>
      </footer>
    </form>
  );
}

export default PositionsForm;
