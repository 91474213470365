import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { MdOutlineContentCopy } from 'react-icons/md';
import { PiClockCountdownFill } from 'react-icons/pi';

import Button from '../../../../components/Button';
import { checkDateAfterNow, timeDifference } from '../../../../common/utils';

export default function PayPix({ pixInfo, onChangePaymentMethod, onConfirm }) {
  function formatTimer(value) {
    return new Date(value * 1000).toISOString().substr(11, 8).slice(3);
  }

  function onConfirmPayment() {
    toast.info('Obrigado por confirmar o pagamento. Os candidatos serão liberados em breve.');
    onConfirm();
  }

  function copyPixCode(e) {
    e.preventDefault();
    try {
      navigator.clipboard.writeText(pixInfo.code);
      toast.success('Código do Pix copiado com sucesso!');
    } catch (error) {
      toast.error('Ocorreu um erro ao copiar o código do Pix, tente novamente.');
    }
  }

  const [timer, setTimer] = useState(10);
  const timerRef = useRef();
  const clear = () => {
    window.clearInterval(timerRef.current);
  };

  useEffect(() => {
    timerRef.current = window.setInterval(() => {
      if (!checkDateAfterNow(pixInfo.expires_at)) {
        clearInterval(timerRef.current);
        toast.error('Seu Pix expirou, por favor, tente novamente.');
        onChangePaymentMethod();
      }
      setTimer(timeDifference(pixInfo.expires_at));
    }, 1000);
    return () => clear();
  }, [timerRef, pixInfo, setTimer, onChangePaymentMethod]);

  return (
    <div className="text-center">
      <div className="text-xs inline-flex font-medium bg-slate-100 dark:bg-slate-300 text-slate-500 dark:text-slate-600 rounded-full text-center px-2.5 py-1 place-items-center">
        <PiClockCountdownFill className="mr-1 w-4 h-4" /> Seu PIX expira em {formatTimer(timer)}
      </div>
      <div className="flex flex-col justify-center items-center py-4">
        <img src={pixInfo.url} alt="QR Code" className="w-48 h-48" />
        <Button type="button" variant="secondary" size="sm" onClick={copyPixCode}>
          <MdOutlineContentCopy className="mr-2" /> Copiar código do Pix
        </Button>
      </div>
      <div className="py-4">
        <Button type="button" className="w-full" onClick={onConfirmPayment}>
          Confirmar pagamento
        </Button>
        <Button variant="secondary" type="button" className="w-full mt-3" onClick={onChangePaymentMethod}>
          Alterar forma de pagamento
        </Button>
      </div>
    </div>
  );
}
