import ContentLoader from 'react-content-loader';

function CandidateSkeleton() {
  return (
    <ContentLoader speed={0.6} viewBox="0 0 246 40" height={40} width={246}>
      <rect x="50" y="15" rx="4" ry="4" width="130" height="15" />
      <circle cx="20" cy="20" r="20" />
    </ContentLoader>
  );
}

export default function CandidatesSkeleton() {
  return (
    <div className="space-y-3 min-h-40 ">
      <CandidateSkeleton />
      <CandidateSkeleton />
      <CandidateSkeleton />
      <CandidateSkeleton />
    </div>
  );
}
