import React, { useState } from 'react';
import FooterNav from '../../../partials/FooterNav';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import { useQuery, useMutation } from 'react-query';
import { createPosition, getPositions } from '../../../common/services/app';

import Header from '../../../partials/Header';
import PositionsForm from './PositionsForm';
import Sidebar from '../../../partials/Sidebar';
import ConfirmationModal from './ConfirmationModal';
import { showMaxPositionsModal } from '../components/MaxPositionsModal';

function Notifications() {
  const navigate = useNavigate();
  const initialState = { open: false };
  const [form, setForm] = useState(initialState);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useQuery('positions', getPositions, {
    onSuccess: (data) => {
      if (data.positions.filter(({ status }) => !['CANCELLED', 'CLOSED'].includes(status)).length > 2)
        showMaxPositionsModal('/positions');
    },
  });

  function mutateFn(data) {
    return toast.promise(createPosition(data), {
      pending: 'Criando vaga...',
      success: 'Vaga criada com sucesso!',
      error: 'Ocorreu um erro ao criar a vaga. Confira os dados e tente novamente.',
    });
  }

  const { mutate, isLoading } = useMutation(mutateFn, {
    onSuccess: (data) => {
      resetForm();
      navigate('/positions', {
        state: { success: 'Vaga criada com sucesso!', data },
      });
    },
  });

  function onSubmit(position) {
    setForm((prevState) => ({ ...prevState, ...position, open: true }));
  }

  function resetForm() {
    setForm(initialState);
  }

  function onModalChange(open) {
    setForm((prevState) => ({ ...prevState, open }));
  }

  return (
    <div className="flex h-[100dvh] overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <ConfirmationModal
        isLoading={isLoading}
        position={form}
        modalOpen={form.open}
        setModalOpen={onModalChange}
        onSubmit={mutate}
      />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="mb-8">
              {/* Title */}
              <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">Nova vaga✨</h1>
            </div>

            {/* Content */}
            <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm mb-8">
              <div className="flex flex-col md:flex-row md:-mr-px">
                <PositionsForm onSubmit={onSubmit} isLoading={isLoading} />
              </div>
            </div>
          </div>
        </main>
        <FooterNav />
      </div>
    </div>
  );
}

export default Notifications;
