import * as Sentry from "@sentry/react";

export function initSentry() {
    Sentry.init({
        dsn: "https://b934d5bb6484a7293d164cebe0961f8a@o4507902564237312.ingest.us.sentry.io/4507902569611264",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ["localhost", /^https:\/\/api-trabalha-ai-15be4ed54be4\.herokuapp.com\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}