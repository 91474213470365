import React, { useState } from 'react';

import Avatar from '../../../../components/Avatar';
import Button from '../../../../components/Button';
import GiveFeedback from '../GiveFeedback';
import FeedbackInfo from './FeedbackInfo';
import RefuseCandidate from '../RefuseCandidate';
import GiveFeedbackModal from '../GiveFeedbackModal';
import RefuseCandidateModal from '../RefuseCandidateModal';
import SendCandidateMessage from '../SendCandidateMessage';
import { FaWhatsapp } from 'react-icons/fa';

import { useQuery } from 'react-query';
import { getPositionCandidatures } from '../../../../common/services/app';

function JobPostCandidates({
  positionId,
  positionName,
  status,
  canReleaseCandidates,
  canViewCandidates,
  openReleaseCandidatesModal,
}) {
  const initialState = {
    open: false,
    positionId,
  };
  const [refusalModalOpen, setRefusalModalOpen] = useState(initialState);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(initialState);

  const { data, isLoading } = useQuery(['position-candidatures', positionId], getPositionCandidatures);
  const releasedPosition = status.includes('RELEASED_CANDIDATES');

  function onModalClose() {
    setRefusalModalOpen(initialState);
    setFeedbackModalOpen(initialState);
  }

  return (
    <div>
      <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-2">
        Candidatos {!isLoading && <span className="_ml-1">({data?.length})</span>}
      </h2>
      {isLoading ? (
        <div className="text-center py-10">Carregando...</div>
      ) : (
        <ul className={`space-y-5 my-6 ${canViewCandidates ? '' : 'blur-sm pointer-events-none'}`}>
          {/* Review */}
          {data?.map(({ Candidate, feedback }) => (
            <li
              key={Candidate.name}
              className={`flex flex-col items-start dark:bg-slate-800 border border-slate-200 dark:border-slate-700 rounded-sm shadow-sm ${canViewCandidates ? '' : 'max-h-20 overflow-hidden'}`}
            >
              <div className="p-4">
                <div className="sm:grow flex items-center text-sm">
                  {/* Icon */}
                  <Avatar className="my-2 mr-3 shrink-0">{Candidate.nameInitials}</Avatar>
                  {/* Position */}
                  <div>
                    <div className="font-medium text-slate-800 dark:text-slate-100">{Candidate.name}</div>
                    <div className="flex flex-wrap items-center space-x-2 whitespace-nowrap">
                      <div>{Candidate.experience_type}</div>
                      <div className="text-slate-400 dark:text-slate-600">·</div>
                      <div>10 min do trabalho</div>
                    </div>
                  </div>
                </div>
                <div className="text-sm mt-2 mb-3">
                  <div className="font-medium text-slate-600 dark:text-slate-100 mb-2">Informações</div>
                  <ul className="text-sm space-y-2 mb-5 dark:text-slate-300">
                    {releasedPosition && (
                      <li className="flex items-center">
                        <FaWhatsapp className="w-5 h-5 fill-current shrink-0 mr-2 text-slate-400 dark:text-slate-500" />
                        <div>Telefone: {Candidate.phone}</div>
                      </li>
                    )}
                    <li className="flex items-center">
                      <svg
                        className="w-4 h-4 fill-current text-slate-400 dark:text-slate-500 shrink-0 mr-3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15 15V5l-5-5H2c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h12c.6 0 1-.4 1-1zM3 2h6v4h4v8H3V2z" />
                      </svg>
                      <div>Escolaridade: {Candidate.education_level}</div>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="w-4 h-4 fill-current text-slate-400 dark:text-slate-500 shrink-0 mr-3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zm0 6c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zm-7-5c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                      </svg>
                      <div>
                        {Candidate.experience_duration} de experiência no setor {Candidate.experience_sector}
                      </div>
                    </li>
                  </ul>
                  {feedback && <FeedbackInfo status={feedback} />}
                </div>
                {/* Tags */}
                <div className="sm:ml-2 mt-2 sm:mt-0">
                  <ul className="flex flex-wrap -m-1">
                    <li className="m-1">
                      <button className="inline-flex items-center justify-center text-xs font-medium leading-5 rounded-full px-2.5 py-0.5 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out">
                        Marketing
                      </button>
                    </li>
                    <li className="m-1">
                      <button className="inline-flex items-center justify-center text-xs font-medium leading-5 rounded-full px-2.5 py-0.5 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out">
                        +4
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className={`w-full border-t border-slate-200 dark:border-slate-700 ${canViewCandidates ? '' : 'hidden'}`}
              >
                <div className="flex divide-x divide-slate-200 dark:divide-slate-700">
                  <SendCandidateMessage
                    phone={Candidate.phone}
                    positionName={positionName}
                    onClick={openReleaseCandidatesModal}
                  />
                  {releasedPosition ? (
                    <GiveFeedback
                      hasFeedback={Boolean(feedback)}
                      onClick={() =>
                        setFeedbackModalOpen((prev) => ({ ...prev, open: true, feedback, candidate: Candidate }))
                      }
                    />
                  ) : (
                    <RefuseCandidate
                      onClick={() => setRefusalModalOpen((prev) => ({ ...prev, open: true, candidate: Candidate }))}
                    />
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
      <GiveFeedbackModal payload={feedbackModalOpen} onClose={onModalClose} />
      <RefuseCandidateModal payload={refusalModalOpen} onClose={onModalClose} />
      {/* Load More */}
      <div className="text-center">
        {!releasedPosition && (
          <Button variant="secondary" disabled={!canReleaseCandidates} onClick={openReleaseCandidatesModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-lock w-5 h-5"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6z" />
              <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0" />
              <path d="M8 11v-4a4 4 0 1 1 8 0v4" />
            </svg>
            Liberar contatos
          </Button>
        )}
      </div>
    </div>
  );
}

export default JobPostCandidates;
