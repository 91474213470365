import React from 'react';
import Button from '../../../../components/Button';

export default function PayFooter({ isCard, isPaying, isTokenizing }) {
  return (
    <div className="mt-6">
      <div className="mb-4">
        <Button
          loading={isPaying}
          disabled={isPaying}
          className="btn w-full bg-indigo-500 hover:bg-indigo-600 text-white"
        >
          {isPaying
            ? isTokenizing
              ? 'Processando...'
              : 'Concluindo pagamento...'
            : isCard
              ? 'Pagar R$49,00'
              : 'Pagar com PIX - R$49,90'}
        </Button>
      </div>
      <div className="text-xs text-slate-500 italic text-center">
        {`Ao clicar em ${isCard ? 'Pagar R$49,00' : 'Pagar com PIX - R$49,90'}, você concorda com os Termos de Serviço e a Política de Privacidade do Trabalha AI. Os pagamentos são processados de forma segura pela Pagar.me (Stone).`}
      </div>
    </div>
  );
}
