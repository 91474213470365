import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/Button';


function PersonName({ display, onSubmit, onPrev }) {
  const [isInvalid, setIsInvalid] = useState(null);

  function handleFormSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    onSubmit({ responsible_name: formData.get('responsible_name') });
  }

  function checkSurname(e) {
    const nameParts = e?.target?.value.split(' ');
    const hasLastName = nameParts.length > 1;
    const lastName = hasLastName ? nameParts[nameParts.length - 1] : '';
    setIsInvalid(!hasLastName || lastName.length < 2);
  }

  return (
    <div className="px-4 py-8" style={{ display: display ? 'block' : 'none' }}>
      <div className="max-w-md mx-auto">
        <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">E qual é o seu nome?</h1>
        <p className="text-md text-gray-600 dark:text-gray-400 mb-8">Pra gente saber como te chamar! 😉</p>
        {/* htmlForm */}
        <form onSubmit={handleFormSubmit}>
          <div className="space-y-4 mb-8">
            {/* Company Name */}
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="responsible_name">
                Seu nome <span className="text-rose-500">*</span>
              </label>
              <input
                required
                id="responsible_name"
                name="responsible_name"
                type="text"
                minLength="3"
                maxLength="100"
                onChange={checkSurname}
                className={`form-input w-full ${isInvalid && 'border-red-500'}`}
              />
              <div className={`text-xs mt-1 ${isInvalid && 'text-rose-500'}`}>
                {isInvalid ? 'Digite seu nome completo' : 'Não compartilhamos esse dado com ninguém'}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <Link className="text-sm underline hover:no-underline" onClick={onPrev}>
              &lt;- Voltar
            </Link>
            <Button type="submit" disabled={isInvalid} className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-auto">
              Prosseguir -&gt;
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PersonName;
