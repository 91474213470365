import React from 'react';

function DefaultEmptyFeedback() {
  return (
    <div className="flex items-center justify-center h-40 text-slate-300 dark:text-slate-700">
      <span>Nenhum registro encontrado</span>
    </div>
  );
}

function DefaultLoadingFeedback() {
  return (
    <div className="animate-pulse">
      <div className="h-4 bg-slate-200 dark:bg-slate-700 rounded mb-2" />
      <div className="h-4 bg-slate-200 dark:bg-slate-700 rounded mb-2" />
      <div className="h-4 bg-slate-200 dark:bg-slate-700 rounded mb-2" />
    </div>
  );
}

function SectionSwitcher({ children, isLoading, isEmpty, EmptyFeedback }) {
  if (!isLoading && isEmpty) {
    return EmptyFeedback ? <EmptyFeedback /> : <DefaultEmptyFeedback />;
  }

  if (isLoading) {
    return <DefaultLoadingFeedback />;
  }
  return children;
}

export default function Section(props) {
  return <SectionSwitcher {...props} />;
}
