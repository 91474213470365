import React from 'react';

import { useQuery } from 'react-query';
import { getCandidateFeedbacks } from '../../../../common/services/app';

import Tooltip from '../../../../components/Tooltip';

export default function FeedbackInfo({ status }) {
  const { data: candidateFeedbacks } = useQuery('candidateFeedbacks', getCandidateFeedbacks);
  const feedback = candidateFeedbacks?.find(({ id }) => id === status);

  return (
    <div className="flex items-center space-x-2">
      <Tooltip size="lg" position="right" bg="dark">
        <div className="text-xs">
          <div className="font-medium text-slate-200 mb-0.5">Sua avaliação:</div>
          <div className="text-slate-400">{feedback.description}</div>
        </div>
      </Tooltip>
      <div className="text-sm font-medium text-slate-500 dark:text-slate-400">
        Candidato avaliado: {feedback.feedback}
      </div>
    </div>
  );
}
