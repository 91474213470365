import React from 'react';
import Transactions from '../../pages/finance/Transactions';

export default function SettingsPanel() {
  return (
    <div className="grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-4">Cobranças e Faturas</h2>
        <section>
          {/* Table */}
          <Transactions />
        </section>
      </div>
    </div>
  );
}
