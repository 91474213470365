import React from 'react';
import Avatar from '../../../../components/Avatar';
import { useQuery } from 'react-query';
import { getPositionCandidatures } from '../../../../common/services/app';

function DashboardAvatars({ positionId }) {
  const { data = [] } = useQuery(['position-candidatures', positionId], getPositionCandidatures);

  return (
    <ul className="flex flex-wrap justify-center sm:justify-start mb-2 sm:mb-0 -space-x-2 -ml-px">
      {data.map(({ Candidate }) => (
        <Avatar className="w-11 h-11" key={Candidate.id}>
          {Candidate.nameInitials}
        </Avatar>
      ))}
    </ul>
  );
}

export default DashboardAvatars;
