import React from 'react';
import { FaPix } from 'react-icons/fa6';
import { BsCreditCard2Front } from 'react-icons/bs';

function InvoicesTableItem(props) {
  const totalColor = (status) => {
    switch (status) {
      case 'paid':
        return 'text-emerald-500';
      case 'Pendente':
        return 'text-amber-500';
      case 'failed':
        return 'text-rose-500';
      default:
        return 'text-slate-500';
    }
  };

  const statusColor = (status) => {
    switch (status) {
      case 'paid':
        return 'bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400';
      case 'Pendente':
        return 'bg-amber-100 dark:bg-amber-400/30 text-amber-600 dark:text-amber-400';
      case 'failed':
        return 'bg-rose-100 dark:bg-rose-500/30 text-rose-500 dark:text-rose-400';
      default:
        return 'bg-slate-100 dark:bg-slate-800 text-slate-500 dark:text-slate-400';
    }
  };

  const statusCopy = (status) => {
    const statusMap = {
      paid: 'Pago',
      failed: 'Falha',
      pending: 'Pendente',
      overdue: 'Vencido',
    };
    return statusMap[status] || status;
  };

  const paymentCopy = (status) => {
    const statusMap = {
      credit_card: 'Cartão de Crédito',
      pix: 'Pix',
    };
    return statusMap[status] || status;
  };

  const typeIcon = (type) => {
    switch (type) {
      case 'credit_card':
        return <BsCreditCard2Front className="w-4 h-4 fill-current text-slate-500 dark:text-slate-600 shrink-0 mr-2" />;
      case 'pix':
        return <FaPix className="w-4 h-4 fill-current text-slate-500 dark:text-slate-600 shrink-0 mr-2" />;
      default:
        return <></>;
    }
  };

  return (
    <tr>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <a href={`/positions/${props.itemCode}/pay`} className="font-medium text-sky-500">
          {props.invoice}
        </a>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className={`font-medium ${totalColor(props.status)}`}>{props.total}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 ${statusColor(props.status)}`}>
          {statusCopy(props.status)}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <a href={`/positions/${props.itemCode}`} className="font-medium text-sky-500">
          {props.customer.split('-')[1]}
        </a>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div>{props.issueddate}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div>{props.paiddate}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="flex items-center">
          {typeIcon(props.type)}
          <div>{paymentCopy(props.type)}</div>
        </div>
      </td>
    </tr>
  );
}

export default InvoicesTableItem;
