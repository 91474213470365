export function formatPhone(value: string): string {
  if (!value) return '';
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '($1) $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  return value;
}

export function formatCpf(v) {
  v = v.replace(/\D/g, "")
  v = v.replace(/(\d{3})(\d)/, "$1.$2")
  v = v.replace(/(\d{3})(\d)/, "$1.$2")
  v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  return v
}


export function formatCnpj(v) {
  return v
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export function formatDate(value: string): string {
  return new Date(value).toLocaleDateString('pt-BR');
}

export function formatPrice(value: number): string {
  return (value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export function timeDifference(endDateString: string) {
  const endDate = new Date(endDateString);
  return (endDate.getTime() - new Date().getTime()) / 1000;
}

export function checkDateAfterNow(dateString: string) {
  return new Date(dateString) > new Date();
}

export function getEnv(): string {
  return window.location.hostname.includes('localhost') ? 'development' : 'production';
}

export function isDevMode() {
  const env = getEnv();
  return env === 'development';
}