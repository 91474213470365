import React from 'react';
import { Link } from 'react-router-dom';

function Greetings({ value, display }) {
  const labels = {
    company: {
      title: 'Cadastro concluído!',
      subtitle: `Seja bem-vindo, ${value?.responsible_name}`,
      cta: 'Cadastrar primeira vaga',
      to: '/positions/new',
    },
    candidate: {
      title: `Cadastro concluído com sucesso!`,
      subtitle: `Quando encontrarmos algo que combine com você, te notificaremos via e-mail e Whatsapp. Você também pode acompanhar as vagas clicando no botão abaixo. Fique de olho!`,
      cta: 'Acompanhar vagas',
      to: '/jobs',
    },
  };

  const { title, subtitle, cta, to } = labels[value?.entity];

  return (
    <div className="px-4 py-8" style={{ display: display ? 'block' : 'none' }}>
      <div className="max-w-md mx-auto">
        <div className="text-center">
          <svg className="inline-flex w-16 h-16 fill-current mb-6" viewBox="0 0 64 64">
            <circle className="text-emerald-100 dark:text-emerald-400/30" cx="32" cy="32" r="32" />
            <path className="text-emerald-500 dark:text-emerald-400" d="m28.5 41-8-8 3-3 5 5 12-12 3 3z" />
          </svg>
          <h1 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 whitespace-break-spaces">
            {title} <br />
          </h1>
          <p className="text-md text-gray-600 dark:text-gray-400 mb-8">{subtitle}</p>
          <Link className="btn bg-indigo-500 hover:bg-indigo-600 text-white" onClick={()=> window.location.replace(to)}>
            {cta} -&gt;
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Greetings;
