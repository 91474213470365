import { useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ModalBasic from '../../../components/ModalBasic';
import { useNavigate } from 'react-router-dom';
import { getUserPaymentMethods, releasePosition } from '../../../common/services/app';

export default function ReleaseCandidates({ positionId, planModalOpen, setPlanModalOpen }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data } = useQuery(['usePaymentMethods'], getUserPaymentMethods);

  function mutateFn(data) {
    return toast.promise(releasePosition(data), {
      pending: 'Liberando informações da vaga...',
      success: 'Informações da vaga liberadas com sucesso!',
      error: 'Ocorreu um erro ao liberar as informações da vaga. Tente novamente mais tarde.',
    });
  }

  function onClose() {
    setPlanModalOpen(false);
  }

  const { mutate } = useMutation(mutateFn, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      onClose();
    },
  });

  const hasFreePromo = data?.free;
  const [option, setOption] = useState(hasFreePromo ? 'PROMO' : 'BUY');

  function selectOption(e) {
    setOption(e.currentTarget.id);
  }

  function getOptionClass(optionId) {
    const activeClass =
      'w-full h-full text-left py-3 px-4 rounded bg-white dark:bg-slate-800 border-2 border-indigo-400 dark:border-indigo-500 shadow-sm duration-150 ease-in-out';
    const nonActiveClass =
      'w-full h-full text-left py-3 px-4 rounded bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm duration-150 ease-in-out';
    return option === optionId ? activeClass : nonActiveClass;
  }

  function handleSubmit() {
    const isPromo = option === 'PROMO';
    if (isPromo) {
      mutate(positionId);
      return;
    }
    navigate('./pay');
  }

  return (
    <ModalBasic id="feedback-modal" modalOpen={planModalOpen} setModalOpen={setPlanModalOpen} title="Liberar contatos">
      {/* Modal content */}
      <div className="px-5 pt-4 pb-1">
        <div className="text-sm">
          <div className="mb-4">
            Selecione uma opção para liberar os contatos dos candidatos que se inscreveram para a vaga:
          </div>
          {/* Options */}
          <ul className="space-y-2 mb-4">
            {hasFreePromo && (
              <li>
                <button id="PROMO" onClick={selectOption} className={getOptionClass('PROMO')}>
                  <div className="flex items-center">
                    <div
                      className={`w-4 h-4 ${option === 'PROMO' ? 'border-4 bg-white border-indigo-500 rounded-full mr-3' : 'border-2 border-slate-300 dark:border-slate-600 rounded-full mr-3'}`}
                    ></div>
                    <div className="grow">
                      <div className="flex flex-wrap items-center justify-between mb-0.5">
                        <span className="font-medium text-slate-800 dark:text-slate-100">
                          1 crédito{' '}
                          <span className="text-xs italic text-indigo-500 align-top">primeira vaga grátis ✨</span>
                        </span>
                        <span className="text-slate-600">
                          <span className="font-medium text-emerald-600">Grátis</span>
                        </span>
                      </div>
                      <div className="text-sm">Promoção Trabalha AI</div>
                    </div>
                  </div>
                </button>
              </li>
            )}
            <li>
              <button id="BUY" onClick={selectOption} className={getOptionClass('BUY')}>
                <div className="flex items-center">
                  <div
                    className={`w-4 h-4 ${option === 'BUY' ? 'border-4 bg-white border-indigo-500 rounded-full mr-3' : 'border-2 border-slate-300 dark:border-slate-600 rounded-full mr-3'}`}
                  ></div>
                  <div className="grow">
                    <div className="flex flex-wrap items-center justify-between mb-0.5">
                      <span className="font-medium text-slate-800 dark:text-slate-100">
                        1 crédito <span className="text-xs italic text-slate-500 align-top">vaga avulsa</span>
                      </span>
                      <span className="text-slate-600">
                        <span className="font-medium text-emerald-600">R$49.90</span>
                      </span>
                    </div>
                    <div className="text-sm">Compre créditos avulsos</div>
                  </div>
                </div>
              </button>
            </li>
          </ul>
          <div className="text-xs text-slate-500">
            Esta ação consome <span className="font-medium">1 crédito</span> e libera a visualização completa dos
            candidatos que se inscreveram para a vaga. Isso inclui acesso ao telefone de contato dos candidatos, bem
            como outras informações relevantes para a vaga.
          </div>
        </div>
      </div>
      {/* Modal footer */}
      <div className="px-5 py-4">
        <div className="flex flex-wrap justify-end space-x-2">
          <button
            className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
            onClick={(e) => {
              e.stopPropagation();
              setPlanModalOpen(false);
            }}
          >
            Cancelar
          </button>
          <button className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white" onClick={handleSubmit}>
            Liberar contatos {option === 'PROMO' ? 'grátis!' : ' por R$49.90'}
          </button>
        </div>
      </div>
    </ModalBasic>
  );
}
