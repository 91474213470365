import React from 'react';

export default function Select(props) {
  const { options } = props;
  return (
    <select defaultValue="" className="form-select w-full" {...props}>
      <option disabled value="">
        Selecione uma opção
      </option>
      {options && options.map((option) => <option key={option}>{option}</option>)}
    </select>
  );
}
