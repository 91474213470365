import React from 'react';
import { TbMessageReply, TbMessage2Up } from 'react-icons/tb';

export default function GiveFeedback({ hasFeedback, onClick }) {
  return (
    <button
      aria-controls="feedback-modal"
      className="block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-200 font-medium px-3 py-4 group"
      onClick={(e) => {
        e.stopPropagation();
        onClick(true);
      }}
    >
      <div className="flex items-center justify-center">
        <svg
          className="w-5 h-5 fill-current text-slate-400 dark:text-sky-600 group-hover:text-sky-500 shrink-0 mr-1"
          viewBox="0 0 14 14"
        >
          {hasFeedback ? <TbMessage2Up /> : <TbMessageReply />}
        </svg>
        <span>{hasFeedback ? 'Alterar avaliação' : 'Avaliar candidato'}</span>
      </div>
    </button>
  );
}
