import React from 'react';
import { Link } from 'react-router-dom';

import Input from '../../../components/Input';

function CandidatePreferences({ display, onSubmit, onPrev }) {
  function handleFormSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    onSubmit({
      work_preference: formData.get('work_preference'),
      course_preference: formData.get('course_preference'),
    });
  }

  return (
    <div className="px-4 py-8" style={{ display: display ? 'block' : 'none' }}>
      <div className="max-w-md mx-auto">
        <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">Preferências profissionais</h1>
        {/* htmlForm */}
        <form onSubmit={handleFormSubmit}>
          <div className="space-y-4 mb-8">
            {/* Position */}
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="work_preference">
                Trabalho de preferência <span className="text-rose-500">*</span>
              </label>
              <div className="text-xs mb-1">Se você pudesse escolher, qual tipo de trabalho gostaria de fazer?</div>
              <Input id="work_preference" name="work_preference" required />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1 flex-nowrap" htmlFor="course_preference">
                Curso de preferência <span className="text-rose-500">*</span>
              </label>
              <div className="text-xs mb-1">Qual tipo de curso você gostaria de fazer para se aprimorar?</div>
              <Input id="course_preference" name="course_preference" required />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <Link className="text-sm underline hover:no-underline" onClick={onPrev}>
              &lt;- Voltar
            </Link>
            <button type="submit" className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-auto">
              Prosseguir -&gt;
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CandidatePreferences;
