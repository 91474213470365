import React from 'react';
import { useQuery } from 'react-query';
import DropdownClassic from './DropdownClassic';
import { formatPhone } from '../common/utils';

import { getCountries } from '../common/services/app.ts';

const defaultValue = {
  id: 'br',
  label: 'Brasil',
  value: '+55',
  number: '',
};

function PhoneInput({ value = defaultValue, onChange }) {
  const { data = [defaultValue], isLoading } = useQuery('countries', getCountries, {
    select: (data) => {
      return data.map(({ id, name, phoneCode }) => ({
        id,
        value: phoneCode,
        label: name,
      }));
    },
  });

  function handleCountry(country) {
    onChange({ ...value, ...country });
  }

  function handlePhone(e) {
    const country = data.find((country) => country.id === value?.id);
    onChange({ ...value, number: formatPhone(e.target.value), country });
  }

  return (
    <div className="grid grid-cols-1 sm:flex gap-3">
      <div>
        <label htmlFor="country" className="block text-sm font-medium mb-1">
          País <span className="text-rose-500">*</span>
        </label>
        <DropdownClassic options={data} value={value?.id} disabled={isLoading} onChange={handleCountry} />
      </div>
      <div>
        <label className="block text-sm font-medium mb-1" htmlFor="email">
          Telefone <span className="text-rose-500">*</span>
        </label>
        <input
          id="phone"
          required
          type="tel"
          value={value?.number}
          maxLength={15}
          onChange={handlePhone}
          placeholder="(00) 90000-0000"
          className="form-input w-full"
        />
      </div>
    </div>
  );
}

export default PhoneInput;
