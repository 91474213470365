import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ModalBasic from '../../../components/ModalBlank';

const modalId = 'max-positions-modal';

export function showMaxPositionsModal(path = '/positions') {
  const event = new CustomEvent(modalId, { detail: { path } });
  window.dispatchEvent(event);
}

export default function MaxPositionsModal() {
  const navigate = useNavigate();
  const initialState = { open: false, to: '' };
  const [modal, setModal] = useState(initialState);

  function closeModal() {
    setModal(initialState);
    navigate(to);
  }

  useEffect(() => {
    const openModal = ({ detail }) => {
      setModal({
        open: true,
        to: detail?.path,
      });
    };
    window.addEventListener(modalId, openModal);

    return () => {
      window.removeEventListener(modalId, openModal);
    };
  }, []);

  const { open, to } = modal;

  return (
    <ModalBasic id="basic-modal" modalOpen={open} setModalOpen={closeModal} title="Basic Modal">
      {/* Modal content */}
      <div className="px-5 pt-4 pb-1">
        <div className="text-sm">
          <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">Limite de vagas abertas atingido</div>
          <div className="space-y-2">
            <p>
              Você já possui 3 vagas abertas. Para abrir uma nova vaga, é necessário encerrar ou cancelar uma das vagas
              atuais.
            </p>
          </div>
        </div>
      </div>
      {/* Modal footer */}
      <div className="px-5 py-4">
        <div className="flex flex-wrap justify-end space-x-2">
          <button
            className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
            onClick={closeModal}
          >
            Fechar
          </button>
          <button className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white" onClick={closeModal}>
            Ir para minhas vagas
          </button>
        </div>
      </div>
    </ModalBasic>
  );
}
