import { getAuth } from 'firebase/auth';
import { Navigate, Outlet } from 'react-router-dom';

const AuthRoute = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  return user ? <Navigate to="/" /> : <Outlet />;
};

export default AuthRoute;
