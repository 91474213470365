import React, { useState } from 'react';

import { useQuery } from 'react-query';
import { getBestPosition } from '../../../common/services/app';

import Header from '../../../partials/Header';
import Sidebar from '../../../partials/Sidebar';
import FooterNav from '../../../partials/FooterNav';

import JobsEmpty from './JobsEmptyState';
import JobPost from './JobPost';
import CompanyProfile from './CompanyProfile';
import Loading from '../../../components/app/Loading';

function Feed() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { isLoading, isFetched, data = {}, refetch } = useQuery('getBestPosition', getBestPosition);

  const { bestMatch, applications = [] } = data;
  const hasActiveApplication = applications.some((application) => application.status === 'PENDING');

  return (
    <div className="flex h-[100dvh] overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="grow">
          <div className="px-4 sm:px-8 lg:px-8 py-8 md:py-0 w-full max-w-9xl mx-auto">
            <div className="xl:flex">
              {/* Left + Middle content */}
              <div className="md:flex flex-1">
                {/* Left content */}

                {/* Middle content */}
                <div className="flex-1 md:ml-8 xl:mx-4 2xl:mx-8">
                  <div className="md:mt-8">
                    <div className="mb-4 sm:mb-0">
                      <h1 className="text-2xl md:text-4xl text-slate-800 dark:text-slate-100 font-bold">Vagas ✨</h1>
                    </div>
                    {isLoading && <Loading />}
                    {isFetched &&
                      (bestMatch?.Position ? (
                        <JobPost job={bestMatch} onSubmit={refetch} applications={applications} />
                      ) : (
                        <>
                          <JobsEmpty hasActiveApplication={hasActiveApplication} />
                          <CompanyProfile applications={applications} />
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <FooterNav />
      </div>
    </div>
  );
}

export default Feed;
