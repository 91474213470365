import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import ModalBasic from '../../../components/ModalBasic';
import { getCandidateRefuseReasons, refuseCandidate } from '../../../common/services/app';

export default function RefuseCandidateModal({ payload, onClose }) {
  const queryClient = useQueryClient();
  const { open, positionId, candidate } = payload;

  const [refusal, setRefusal] = useState({
    refuseReason: '',
    feedback: '',
  });

  const { data: motives = [], isLoading } = useQuery('candidateRefuseMotives', getCandidateRefuseReasons);
  function mutateFn(data) {
    return toast.promise(refuseCandidate(data), {
      pending: 'Guardando informações...',
      success: 'Candidato recusado com sucesso.',
      error: 'Ocorreu um ao recusar o candidato. Tente novamente mais tarde.',
    });
  }

  const { mutate } = useMutation(mutateFn, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      onClose();
    },
  });

  function onSubmit(e) {
    e.preventDefault();
    mutate({ positionId, candidateId: candidate.id, refusal });
  }

  const selectedOtherRefuseReason = refusal.refuseReason === 'OTHER';

  return (
    <ModalBasic id="feedback-modal" modalOpen={open} setModalOpen={onClose} title="Recusar candidato?">
      <form onSubmit={onSubmit}>
        <div className="px-4 py-2 sm:px-5 sm:py-4">
          {/* Modal content */}
          <div className="text-sm mb-4">
            <div className="space-y-2">
              <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">
                Que pena que você não deseja continuar com o candidato{' '}
                <span className="font-medium">{candidate?.name.split(' ')[0]}</span>
              </div>
              <p>Para nos ajudar a melhorar a experiência de recrutamento, informe o motivo da recusa:</p>
            </div>
          </div>
          <div className="space-y-2">
            {/* Checkbox */}
            <div className="py-1">
              <label className="block text-sm font-medium mb-1" htmlFor="motive">
                Motivo <span className="text-rose-500">*</span>
              </label>
              <div className="flex flex-wrap items-center -m-2 mt-1">
                {motives.map((motive) => (
                  <div key={motive.id} className="m-2">
                    {/* Start */}
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="motive"
                        required
                        className="form-radio"
                        onChange={() => setRefusal({ ...refusal, refuseReason: motive.id })}
                        checked={motive.id === refusal.refuseReason}
                      />
                      <span className="text-sm ml-2">{motive.motive}</span>
                    </label>
                    {/* End */}
                  </div>
                ))}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="feedback">
                {selectedOtherRefuseReason ? (
                  <span>
                    Qual foi o motivo? <span className="text-rose-500">*</span>
                  </span>
                ) : (
                  <span>Deseja adicionar algum comentário?</span>
                )}
              </label>
              <textarea
                id="feedback"
                name="feedback"
                onChange={(e) => setRefusal({ ...refusal, feedback: e.target.value })}
                className="form-textarea w-full px-2 py-1"
                rows="3"
                required={selectedOtherRefuseReason}
              ></textarea>
            </div>
            <div className="text-xs text-slate-500">
              <p>Ao recusar um candidato, ele não poderá mais se candidatar a esta vaga.</p>
              <p className="mt-2">
                Para evitar o mau uso do Trabalha AI, limitamos a quantidade de recusas de candidatos. Você ainda pode
                recusar <span className="font-medium text-slate-800">2</span> candidatos.
              </p>
            </div>
          </div>
        </div>
        {/* Modal footer */}
        <div className="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
          <div className="flex flex-wrap justify-end space-x-2">
            <button
              disabled={isLoading}
              className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              Cancelar
            </button>
            <button disabled={isLoading} className="btn-sm bg-rose-500 hover:bg-rose-600 text-white">
              Recusar candidato
            </button>
          </div>
        </div>
      </form>
    </ModalBasic>
  );
}
