import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ModalBlank from '../../../components/ModalBlank';
import ModalImage from '../../../images/modal-image.jpg';

export function showPromoModal(path = '/positions') {
  const event = new CustomEvent('open-promo-modal', { detail: { path } });
  window.dispatchEvent(event);
}

export const ALREADY_SEEN_PROMO_KEY = 'ALREADY_SEEN_PROMO';

export default function PromoFirstPurchaseModal() {
  const navigate = useNavigate();
  const initialState = { open: false, to: '' };
  const [modal, setModal] = useState(initialState);

  function closeModal() {
    setModal(initialState);
  }

  useEffect(() => {
    const openModal = ({ detail }) => {
      if (sessionStorage.getItem(ALREADY_SEEN_PROMO_KEY)) return;
      sessionStorage.setItem(ALREADY_SEEN_PROMO_KEY, true);
      setModal({
        open: true,
        to: detail?.path,
      });
    };
    window.addEventListener('open-promo-modal', openModal);

    return () => {
      window.removeEventListener('open-promo-modal', openModal);
    };
  }, []);

  const { open, to } = modal;

  return (
    <ModalBlank id="promo-modal" modalOpen={open} setModalOpen={closeModal}>
      <div className="relative">
        <img className="w-full" src={ModalImage} width="460" height="200" alt="New on Mosaic" />
        {/* Close button */}
        <button
          className="absolute top-0 right-0 mt-5 mr-5 text-slate-50 hover:text-white"
          onClick={(e) => {
            e.stopPropagation();
            setModal(false);
          }}
        >
          <div className="sr-only">Fechar</div>
          <svg className="w-4 h-4 fill-current">
            <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
          </svg>
        </button>
      </div>
      <div className="p-5">
        {/* Modal header */}
        <div className="mb-2">
          <div className="mb-3">
            <div className="text-xs inline-flex font-medium bg-indigo-100 dark:bg-indigo-500/30 text-indigo-600 dark:text-indigo-400 rounded-full text-center px-2.5 py-1">
              Oferta Especial!
            </div>
          </div>
          <div className="text-lg font-semibold text-slate-800 dark:text-slate-100">
            Encontramos os candidatos ideais para você!
          </div>
        </div>
        {/* Modal content */}
        <div className="text-sm mb-5">
          <div className="space-y-2">
            <p>
              Boas notícias! Após uma cuidadosa análise, identificamos cinco excelentes candidatos para a sua vaga.{' '}
            </p>
            <p>
              Como parte de nossa oferta exclusiva, sua primeira liberação de dados de contato é{' '}
              <span className="font-semibold">gratuita </span>! Descubra quem são os talentos que temos para você.
            </p>
          </div>
        </div>
        {/* Modal footer */}
        <div className="flex flex-wrap justify-end space-x-2">
          <button
            className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
            onClick={() => {
              closeModal();
              navigate(to);
            }}
          >
            Descobrir Candidatos!
          </button>
        </div>
      </div>
    </ModalBlank>
  );
}
