import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyCHRQNT7oX1abydllBc0k1S--za-JClAHU',
    authDomain: 'api-ru-delivery.firebaseapp.com',
    projectId: 'api-ru-delivery',
    storageBucket: 'api-ru-delivery.appspot.com',
    messagingSenderId: '134192761123',
    appId: '1:134192761123:web:46780bbd696918987f2496',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence)

// Use device language
auth.useDeviceLanguage();

// Turn off phone auth app verification if testing
// auth.settings.appVerificationDisabledForTesting = process.env.NODE_ENV === 'development';

export default auth