import React, { useState } from 'react';
import PayHeader from './PayHeader';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderBg from './HeaderBg';
import PayFooter from './PayFooter';
import PaymentToggle from './PaymentToggle';
import PersonalInfoForm from './PersonalInfoForm';
import CardForm from './CardForm';
import { getPosition, payAndReleasePosition, tokenizeCard } from '../../../../common/services/app';
import PreviewCandidates from './PreviewCandidates';
import PayPix from './PayPix';
import { checkDateAfterNow } from '../../../../common/utils';

function Pay() {
  const { id } = useParams();
  const [changeMethod, setChangeMethod] = useState(false);
  const [showQRCode, setShowQRCode] = useState(null);
  const [form, setForm] = useState({ cpf: '', email: '' });
  const navigate = useNavigate();

  const [paymentMethod, setPaymentMethod] = useState('CARD');
  const isCard = paymentMethod === 'CARD';

  function handleGeneratedPix(pix, newOrder) {
    if (changeMethod && !newOrder) return;
    if (checkDateAfterNow(pix.expires_at)) {
      setShowQRCode(pix);
      toast.info('Pagamento pendente. Por favor, realize o pagamento do Pix para liberar os contatos dos candidatos.');
    }
  }

  function backToPositionPage() {
    navigate(`/positions/${id}`);
  }

  // Endpoint to get position data
  const { data } = useQuery(['position', id], getPosition, {
    onSuccess: ({ order }) => {
      if (order.status === 'paid') {
        toast.success('Pagamento já realizado. Seus contatos estão liberados.');
        backToPositionPage();
      }
      handleGeneratedPix(order?.pix);
    },
  });

  // Endpoint to pay and release position
  const { mutate, isLoading } = useMutation(payAndReleasePosition, {
    onSuccess: ({ status, pix }) => {
      if (status === 'paid') {
        toast.success('Pagamento realizado com sucesso! Seus contatos estão liberados.');
        backToPositionPage();
      }
      setChangeMethod(false);
      handleGeneratedPix(pix, true);
    },
    onError: () => {
      toast.error('Ocorreu um erro ao processar o pagamento. Por favor, tente novamente.');
    },
  });
  const { mutate: tokenize, isLoading: isTokenizing } = useMutation(tokenizeCard, {
    onSuccess: sendOrder,
    onError: () => {
      toast.error('Os dados do cartão estão incorretos. Por favor, verifique e tente novamente.');
    },
  });

  function sendOrder(card = {}) {
    const data = {
      ...card,
      cpf: form.cpf,
      email: form.email,
    };
    mutate({ id, data });
  }

  function submitCreditCard(formData) {
    const [exp_month, exp_year] = formData.exp_date.split('/');
    delete formData.exp_date;

    tokenize({
      ...formData,
      number: formData.number.trim().replace(/\s/g, ''),
      exp_month,
      exp_year,
    });
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = Object.fromEntries(new FormData(e.target).entries());
    const isPixPayment = !formData?.exp_date;
    isPixPayment ? sendOrder() : submitCreditCard(formData);
  };

  const { experience_type } = data || {};

  const isPaying = isLoading || isTokenizing;

  return (
    <>
      <PayHeader id={id} />
      <main className="grow">
        <form onSubmit={onSubmit}>
          <HeaderBg />
          <div className="relative px-4 sm:px-6 lg:px-8 pb-8 max-w-lg mx-auto">
            <div className="bg-white dark:bg-slate-800 px-8 pb-6 rounded-b shadow-lg">
              {/* Card header */}
              <div className="text-center mb-6">
                <div className="inline-flex justify-center mb-4 -mt-8">
                  <PreviewCandidates positionId={id} />
                </div>
                <h1 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-semibold mb-2">
                  {showQRCode ? 'Aguardando pagamento' : 'Liberar contatos'}
                </h1>
                {showQRCode ? (
                  <div className="text-sm">
                    Abra o aplicativo do seu banco e realize o pagamento do Pix para liberar os contatos dos candidatos
                  </div>
                ) : (
                  <div className="text-sm">
                    Realize o pagamento e tenha acesso imediato aos contatos dos candidatos para a vaga de{' '}
                    <span className="font-medium text-slate-700 dark:text-slate-100">{experience_type}</span>
                  </div>
                )}
              </div>

              <div className={isPaying ? 'opacity-50 pointer-events-none' : ''}>
                {/* Toggle */}
                {!showQRCode && <PaymentToggle paymentMethod={paymentMethod} onChange={setPaymentMethod} />}
                {/* Form */}
                <div>
                  <div className="space-y-4">
                    {showQRCode ? (
                      <PayPix
                        pixInfo={showQRCode}
                        onConfirm={backToPositionPage}
                        onChangePaymentMethod={() => {
                          setShowQRCode(null);
                          setChangeMethod(true);
                        }}
                      />
                    ) : (
                      <>
                        {isCard && <CardForm isPaying={isPaying} />}
                        <PersonalInfoForm form={form} isPaying={isPaying} onChange={setForm} />
                      </>
                    )}
                  </div>
                  {/* htmlForm footer */}
                  {!showQRCode && <PayFooter isCard={isCard} isPaying={isPaying} isTokenizing={isTokenizing} />}
                </div>
              </div>
            </div>
          </div>
        </form>
      </main>
    </>
  );
}

export default Pay;
