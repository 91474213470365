import React, { useState } from 'react';
import Stepper from '../Stepper';

import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { createCompany, createCandidate } from '../../../../common/services/app.ts';

// Question components
import PersonName from './questions/PersonName';
import CompanyName from './questions/CompanyName';
import AddressQuestion from './questions/AddressQuestion';
import CompanyOrCandidate from '../CompanyOrCandidate';
import Greetings from '../Greetings';

// Candidate questions
import EmploymentStatus from '../EmploymentStatus.jsx';
import CandidateSector from '../CandidateSector.jsx';
import ProfessionalData from '../ProfessionalData.jsx';
import CandidatePreferences from '../CandidatePreferences.jsx';
import CandidateTest from '../CandidateTest.jsx';
import CandidatePersonalInfo from '../CandidatePersonalInfo.jsx';

function CompanyForm() {
  const [step, setStep] = useState(0);
  const [form, setForm] = useState({
    entity: 'company',
  });

  const createEntity = form?.entity === 'candidate' ? createCandidate : createCompany;

  function mutateFn(data) {
    return toast.promise(createEntity(data), {
      pending: 'Guardando informações...',
      success: 'Cadastro realizado com sucesso 👌',
      error: 'Ocorreu um erro ao completar seu cadastro. Confira os dados e tente novamente.',
    });
  }

  const { mutate, isLoading } = useMutation(mutateFn, {
    onSuccess: () => {
      setStep((prev) => prev + 1);
    },
  });

  return (
    <div className="w-full md:w-1/2">
      <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
        <Stepper
          form={form}
          step={step}
          onFinish={mutate}
          isLoading={isLoading}
          onFormChange={setForm}
          onStepperChange={setStep}
        >
          <CompanyOrCandidate />
          <CompanyName entity="company" />
          <PersonName entity="company" />
          <AddressQuestion entity="company" />
          <Greetings entity="company" />

          <EmploymentStatus entity="candidate" />
          <CandidateSector entity="candidate" />
          <ProfessionalData entity="candidate" />
          <CandidatePreferences entity="candidate" />
          <CandidateTest entity="candidate" />
          <CandidatePersonalInfo entity="candidate" />
          <AddressQuestion entity="candidate" />
          <Greetings entity="candidate" />
        </Stepper>
      </div>
    </div>
  );
}

export default CompanyForm;
