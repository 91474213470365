import { useQuery } from 'react-query';
import { getPositionCandidatures } from '../../../../common/services/app';
import { useNavigate } from 'react-router-dom';

import CandidatesList from './CandidatesList';
import { SlPeople } from 'react-icons/sl';
import Button from '../../../../components/Button';
import { TYPE_ENUM } from '../../../../partials/job/JobListItem';

export default function Candidates({
  className,
  canReleaseCandidates,
  canViewCandidates,
  positionId,
  status,
  onShowDetails,
}) {
  const navigate = useNavigate();
  const hasCandidates = status !== 'WAITING_CANDIDATES';
  const { data, isLoading } = useQuery(['position-candidatures', positionId], getPositionCandidatures, {
    enabled: hasCandidates,
  });
  const { label, color } = TYPE_ENUM[status] || {};

  const activeCandidatures = data?.length;

  const onShowDetailsHandle = () => {
    onShowDetails();
  };

  return (
    <div
      onClick={onShowDetailsHandle}
      onKeyPress={onShowDetailsHandle}
      role="button"
      tabIndex="0"
      className={`bg-white dark:bg-slate-800 p-5 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 lg:w-72 xl:w-80 ${className}`}
    >
      <div className="flex justify-between space-x-1 mb-5">
        <div className="text-sm text-slate-800 dark:text-slate-100 font-semibold">
          Candidatos {!!activeCandidatures && <span>({activeCandidatures})</span>}
        </div>
        <span
          className={`text-xs inline-flex font-medium rounded-full text-center px-2.5 py-1 justify-center ${color}`}
        >
          {label}
        </span>
      </div>
      <CandidatesList
        status={status}
        data={data}
        hasCandidates={hasCandidates}
        canViewCandidates={canViewCandidates}
        isLoading={isLoading}
      />
      {hasCandidates && status !== 'CANCELLED' && (
        <div className="mt-4">
          <Button
            size="sm"
            variant="secondary"
            disabled={!canReleaseCandidates}
            onClick={onShowDetailsHandle}
            className="w-full dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-none"
          >
            <SlPeople className="mr-1" />
            Ver detalhes
          </Button>
        </div>
      )}
      {status === 'CANCELLED' && (
        <div className="mt-4">
          <Button
            size="sm"
            onClick={() => {
              navigate('/positions/new');
            }}
            className="w-full dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-none"
          >
            Crie uma nova vaga agora
          </Button>
        </div>
      )}
    </div>
  );
}
